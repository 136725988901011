<template>
  <v-container>
    <h2 class="mb-1">User Level</h2>
    <v-row>
      <v-col class="d-flex">
        <!--        <p class="text-subtitle-1 font-weight-bold">-->
        <!--          Got questions? Call or Whatsapp {{ siteData.phone }}-->
        <!--        </p>-->
        <v-spacer></v-spacer>
      </v-col>
    </v-row>
    <v-card elevation="0" class="my-5">
      <div class="">
        <div class="px-6 py-3 flex flex-col items-center">
          <div class="flex flex-col items-center justify-center h-full">
            <div class="relative">
              <v-avatar color="primary" size="50">
                <span class="text-white">{{
                  user.firstName | getInitials
                }}</span>
              </v-avatar>
            </div>
            <div class="flex flex-col items-center mt-5">
              <div class="flex flex-row items-center gap-x-2">
                <span
                  class="px-2 py-1 block rounded-full text-sm text-primary font-medium font-mono border-primary border"
                  style="width: max-content; z-index: 1"
                >
                  {{ cashback.balance }} points
                </span>
                <span
                  class="px-2 py-1 block bg-accent rounded-full text-xs"
                  style="width: max-content; z-index: 1"
                >
                  <span
                    class="bg-yellow-800 rounded-full text-white inline-flex w-5 h-5 items-center justify-center"
                  >
                    {{ currentRank.level }}
                  </span>
                  {{ currentRank.title }}
                </span>
              </div>
              <span class="capitalize font-medium text-gray-700 mt-1">{{
                user.username
              }}</span>
            </div>
          </div>
        </div>
      </div>
    </v-card>
    <div class="mt-10">
      <p class="text-center">You still have some ladders to climb.</p>
      <div
        v-for="rank in ranks"
        :key="rank._id"
        class="flex flex-col gap-y-4 items-center"
      >
        <div class="flex flex-row items-center gap-x-4 relative">
          <div class="absolute left-0 top-1/2 transform -translate-y-1/2">
            <v-progress-circular
              v-if="rank._id === currentRank._id"
              :rotate="-90"
              :size="112"
              :value="rankDetails.progress"
              color="primary"
            >
            </v-progress-circular>
          </div>
          <div
            class="text-center w-28 h-28 p-4 flex-shrink-0 rounded-full bg-gray-200 flex flex-col items-center justify-center mt-4"
          >
            <span v-if="rank.badge">
              <img :src="rank.badge" alt="rank badge" class="w-16 h-16" />
            </span>
            <span class="font-medium">{{ rank.title }}</span>
          </div>
          <div v-if="rank._id === currentRank._id" class="w-full">
            <div
              class="text-center bg-gray-200 p-4 rounded-full mt-2 flex items-center justify-center"
            >
              <p class="text-sm mb-0 ml-2">
                Trade up to {{ rankDetails.minTransactionAmount }} to get to the
                next level.
                <a
                  class="underline text-primary"
                  href="https://blog.snappyexchange.com/category/what-is-a-leaderboard"
                  >Learn More</a
                >
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </v-container>
</template>

<script>
import { mapState } from 'vuex'
import rank from '../../api/users/rank'
import { getCurrencyCode } from '../../utils/countryHelpers'
// import millify from 'millify'
import { numberWithCommas } from '@/utils/helpers'
import cashback from '@/api/users/cashback'

export default {
  name: 'UserLevel',
  data() {
    return {
      currentRank: {},
      nextRank: {},
      totalAmountTrader: 0,
      ranks: []
    }
  },
  computed: {
    ...mapState({
      user: ({ user }) => user.user,
      siteData: (state) => state.user.siteSettings,
      cashback: (state) => state.user.cashback
    }),
    rankDetails() {
      if (!this.currentRank.values) {
        return {
          progress: 0
        }
      }

      console.log(getCurrencyCode(this.userCountry))
      console.log(this.currentRank)

      const currentRankValue = this.currentRank?.values.find(
        (value) => value.currency === getCurrencyCode(this.userCountry)
      )

      const nextRankValue = this.nextRank?.values.find(
        (value) => value.currency === getCurrencyCode(this.userCountry)
      )

      const totalAmountSpent = this.totalAmountTrader

      if (!currentRankValue || !nextRankValue) {
        return {
          progress: 0
        }
      }

      const percentageProgress =
        ((totalAmountSpent - currentRankValue.lowerLimit) /
          (currentRankValue.upperLimit - currentRankValue.lowerLimit)) *
        100

      return {
        progress: Number(percentageProgress.toFixed(2)),
        rank: this.currentRank.title,
        amountNeeded: nextRankValue.lowerLimit - totalAmountSpent,
        minTransactionAmount: `${getCurrencyCode(
          this.userCountry
        )} ${numberWithCommas(Number(nextRankValue.lowerLimit))}`
      }
    }
  },
  created() {
    this.getAllRanks()
    this.getUserCurrentRank()
    this.getCashback()
  },
  methods: {
    async getCashback() {
      const res = await cashback.cashbackApi().getCashback()
      this.$store.commit('user/setCashback', res.data.data)
    },
    async getAllRanks() {
      const res = await rank.rankApi().getAllRanks({
        limit: 0
      })

      if (res.error) {
        this.$store.dispatch('alert', {
          message:
            res.errorMessage.message ||
            `${res.internalError.message}, please try again`,
          status: true,
          error: true
        })

        return
      }

      this.ranks = res.data.data.data.sort((a, b) => a.level - b.level)
    },
    async getUserCurrentRank() {
      const res = await rank.rankApi().getUserCurrentRank()

      if (res.error) {
        this.$store.dispatch('alert', {
          message:
            res.errorMessage.message ||
            `${res.internalError.message}, please try again`,
          status: true,
          error: true
        })

        return
      }

      this.currentRank = res.data.data.currentRank
      this.nextRank = res.data.data.nextRank
      this.totalAmountTrader = res.data.data.totalAmount
    }
  },
  filters: {
    truncateUsername(value) {
      if (!value) return ''
      const length = value.length
      const show = Math.floor(length * 0.6)
      return value.slice(0, show) + '***'
    },
    getInitials(value) {
      if (!value) return 'AA'
      return value[0]
    },
    formatAmount(value) {
      if (!value) return 0
      return numberWithCommas(Number.parseFloat(value).toFixed(2))
    }
  }
}
</script>

<style></style>
